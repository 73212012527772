<template>
	<div>
		<div class="container">
			<div class="top">
				<h2>{{obj.status===0?'待缴费':obj.status===1?'部分缴费':obj.status===2?'缴费完成':'已关闭'}}</h2>
				<span>服务单号：{{obj.code}}</span>
			</div>
			<div class="lattice">
				<h3 class="title">就诊人信息：</h3>
				<div class="nameWrap flex">
					<div>就诊人：<sapn style="color: #00A0E9;" @click.stop="onSubmit(obj.info_id)">{{obj.name}}</sapn>
					</div>
					<div>性别：{{obj.sex}}</div>
					<div>年龄：{{obj.age}}岁</div>
					<div>联系电话：{{obj.mobile}}</div>
					<div>看诊项目：{{obj.disease_name}}</div>
					<div>专家：{{obj.medic_name}}</div>
					<div>护士：{{obj.nurse_name}}</div>
					<div>疗效评估：
						<el-select v-model="obj.assess" :disabled="getUserType=='护士'" placeholder="请选择"
							@change="assessSelect">
							<el-option label="治愈" value="治愈"></el-option>
							<el-option label="好转" value="好转"></el-option>
							<el-option label="无变化" value="无变化"></el-option>
							<el-option label="加重" value="加重"></el-option>
							<el-option label="失败" value="失败"></el-option>
						</el-select>
					</div>
				
				</div>
				<div class="nameWrap flex">
					<div>看诊日期：{{obj.symptom}}</div>
				</div>
			</div>
			<h3 class="title">诊疗单：</h3>
			<el-table :data="obj.diagnosis_arrs" style="width: 100%;margin-bottom:20px;">
				<el-table-column type="index" label="序号" width="50"> </el-table-column>
				<el-table-column prop="diagnosis_name" label="诊疗项目"> </el-table-column>
				<el-table-column prop="specification" label="规格型号"> </el-table-column>
				<el-table-column prop="unit" label="单位"> </el-table-column>
				<el-table-column prop="count" label="数量"></el-table-column>
				<el-table-column prop="msg" label="备注"> </el-table-column>
			</el-table>
			<div class="markup" v-if="obj.diagnosis_msgs" style="display: flex;align-items: center;">
				<p class="markup-title">诊疗备注： </p>
				<p class="markup-content">{{obj.diagnosis_msgs}}</p>
			</div>
			<h3 class="title">药品单：</h3>
			<el-table :data="obj.drug_arr" style="width: 100%;margin-bottom:20px;">
				<el-table-column type="index" label="序号" width="50"> </el-table-column>
				<el-table-column prop="medicine_name" label="药品"> </el-table-column>
				<el-table-column prop="specification" label="规格型号"> </el-table-column>
				<el-table-column prop="unit" label="单位"> </el-table-column>
				<el-table-column prop="counts" label="数量"></el-table-column>
				<el-table-column prop="count" label="库存"> </el-table-column>
			</el-table>
			<div class="markup" v-if="obj.drug_msgs" style="display: flex;align-items: center;">
				<p class="markup-title">药品备注： </p><!--  -->
				<p class="markup-content">{{obj.drug_msgs}}</p>
			</div>
			<div class="titlePrint">
				<h3 class="title">体检单：</h3>
				<button v-print="'#printMe'" class="Print">打印体检单</button>
			</div>
			<div id="printMe">
				<el-table :data="obj.health_arr" style="width: 100%;margin-bottom:20px;">
					<el-table-column type="index" label="序号" width="50"></el-table-column>
					<el-table-column prop="health_name" label="药品"></el-table-column>
					<el-table-column prop="price" label="价格"></el-table-column>
					<el-table-column prop="msg" label="临床意义" width="600">
						<template #default="scope">
							<span style="font-size: 16px;">{{ scope.row.msg }}</span>
						</template>
					</el-table-column>
				</el-table>
			</div>
			<h3 class="title">会员服务：</h3>
			<el-table :data="obj.vip_arr" style="width: 100%;margin-bottom:20px;">
				<el-table-column type="index" label="序号" width="50"> </el-table-column>
				<el-table-column prop="serve_name" label="服务名称"> </el-table-column>
				<el-table-column prop="count" label="数量"></el-table-column>
				<el-table-column prop="unit" label="单位"> </el-table-column>
				<el-table-column prop="msg" label="备注"> </el-table-column>
			</el-table>
			<h3 class="title">费用核算：</h3>
			<div class="costbox">
				<div class="costboxbdr">
					<span class="cost">原始金额:{{ obj.count_cost }}</span>
					<span class="cost">支付方式:{{ obj.pay }}</span>
					<br />
					<span class="cost">会员优惠金额:{{ obj.vip_discounts }}</span>
					<span class="cost">会员抵扣服务金额:{{ obj.vip_deduction }}</span>
					<span class="cost">申请优惠金额:{{ obj.iscounts_cost }}</span>
					<span class="cost">审批单号:{{ obj.check_code }}</span>
					<span class="cost">第三方服务费:{{ obj.other_cost }}</span>
					<span class="cost" v-if="obj.new_str">{{ obj.new_str }}</span>
					<br />
					<span class="cost">应收金额:{{ obj.receivable }}</span>
					<span class="cost">实收金额:{{ obj.reality_cost }}</span>
					<span class="cost">赊账金额:{{ obj.on_account }}</span>
					<br />
					<!-- {{obj.signature}} -->
					<div v-if="obj.signature" class="confirm">
						<span class="confirmSpan">确认签字：</span>
						<el-image :src="obj.signature" :preview-src-list="[obj.signature]"
							style="width: 173px; height: 62px;  vertical-align: middle;" fit="cover"></el-image>
					</div>
				</div>
			</div>
			<h3 class="title">疗程：</h3>
			<el-table :data="obj.diagnosis_arr" style="width: 100%;margin-bottom:20px;">
				<el-table-column prop="name" label="次数"> </el-table-column>
				<el-table-column prop="date" label="日期"> </el-table-column>
				<el-table-column prop="salesman_name" label="健康顾问"> </el-table-column>
				<el-table-column prop="imgs" label="知情同意书签字">
					<template #default="scope">
						<el-image v-if="scope.row.imgs" style="width: 100px; height: 100px" :src="scope.row.imgs"
							:preview-src-list="[scope.row.imgs]" fit="cover"></el-image>
						<span v-else>暂无数据</span>
					</template>
				</el-table-column>
				<el-table-column prop="img" label="诊后注意事项签字">
					<template #default="scope">
						<el-image v-if="scope.row.img" style="width: 100px; height: 100px" :src="scope.row.img"
							:preview-src-list="[scope.row.imgs]" fit="cover"></el-image>
						<span v-else>暂无数据</span>
					</template>
				</el-table-column>
			</el-table>
		</div>
	</div>
</template>

<script>
	import {
		diagnosisDetails,
		infoAssess
	} from "../../api/index";
	export default {
		name: "curativeInfo",
		data() {
			return {
				obj: {}
			};
		},
		created() {
			let id = this.$route.query.id
			this.getData(id);
		},
		computed: {
			getUserType() { //判断专家和护士
				return this.$store.state.userInfo.type;
			},
		},
		methods: {
			getData(id) {
				diagnosisDetails({
					id
				}).then(res => {
					this.obj = res
				});
			},

			// 疗效评估
			assessSelect() {
				let id = this.obj.id
				let assess = this.obj.assess
				console.log(id, assess)
				infoAssess({
					id,
					assess
				}).then(() => {
					this.$notify({
						title: '提示',
						message: '疗效评估修改成功',
						type: 'success'
					});
				})
			},

			onSubmit(id) {
				console.log('submit!');
				this.$router.push({
					path: '/memberInfo',
					query: {
						id: id
					}
				})
			},

			// 编辑操作
			handleEdit(row) {
				this.form = row;
				this.$router.push({
					path: '/memberInfo',
					query: {
						userid: row.id
					}
				})
			},


		}
	};
</script>

<style scoped>
	@page {
		margin: 0;
	}

	@media print {
		.background {
			display: block;
			background: red;
		}
	}

	.title {
		margin-bottom: 20px;
	}

	.top {
		display: flex;
		align-items: flex-end;
		margin-bottom: 20px;
	}

	.top span {
		font-size: 14px;
		color: #666;
		margin-left: 20px;
	}

	.nameWrap {
		font-size: 14px;
		padding-bottom: 20px;
	}

	.lattice {
		border: 1px solid #ccc;
		border-radius: 5px;
		padding: 20px;
		box-sizing: border-box;
		margin-bottom: 20px;
	}

	.costbox {
		width: 100%;
		margin-bottom: 20px;
	}

	.costboxbdr {
		width: 100%;
		padding-top: 23px;
		padding-bottom: 23px;
		border-top: 2px solid black;
		border-bottom: 2px solid #333;
	}

	.cost {
		display: inline-block;
		width: 200px;
		height: 47px;
		line-height: 47px;
	}

	.titlePrint {
		position: relative;
	}

	.Print {
		position: absolute;
		right: 0;
		top: 0;
		width: 90px;
		color: white;
		height: 35px;
		border: none;
		outline: none;
		border-radius: 3px;
		background-color: rgba(64, 158, 255);
	}

	.markup {
		margin-bottom: 40px;
	}

	.markup-title {
		font-size: 19px;
		font-weight: 550;
	}

	.markup-content {
		/* margin-top: 10px; */
		/* margin-left: 60px; */
		font-size: 14px;
		color: #606266;
	}
</style>
